import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import config from "../../../utils/siteConfig";

const ImageMeta = ({ image }) => {
    let shareImageWidth, shareImageHeight;

    if (!image) {
        image =
            "https://www.tombatruites.cat/content/images/2018/11/tombatruites-square-1080.png";
        shareImageWidth = 1080;
        shareImageHeight = 1080;
    } else {
        shareImageWidth = config.shareImageWidth;
        shareImageHeight = config.shareImageHeight;
    }

    return (
        <Helmet>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={image} />
            <meta property="og:image" content={image} />
            <meta property="og:image:width" content={shareImageWidth} />
            <meta property="og:image:height" content={shareImageHeight} />
        </Helmet>
    );
};

ImageMeta.propTypes = {
    image: PropTypes.string
};

export default ImageMeta;
